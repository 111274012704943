<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Add new location number"
    primary-button-text="Add country"
    secondary-button-text="Cancel"
    :disabled="!data.country_id || loading"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <form class="h-full" @submit.prevent>
      <UiInputSelect
        v-model="data.country_id"
        :items="countries"
        label="Location"
        name="country_id"
        placeholder="Choose country"
        class="mb-6"
        :error="useGetFieldErrors(v$, ['country_id'])"
        @update:model-value="preselectCountryItems()"
      />
      <div v-for="(number, index) in data.phones" :key="index">
        <UiInputPhone
          v-model="number.phone"
          name="Phone"
          :label="data.phones.length > 1 ? `Phone number ${index + 1}` : `Phone number`"
          placeholder="Add a number"
          class="mb-6"
          :error="
            v$.phones.$each.$response.$errors[index].phone[0]
              ? v$.phones.$each.$response.$errors[index].phone[0].$message.toString()
              : ''
          "
        />
      </div>
      <UiButtonBase id="add_another" size="big" @click="addNumber()">Add another number</UiButtonBase>
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { InputItemCountry, Phone } from '@/types'
import { useUiStore } from '~/store/ui'

const emits = defineEmits(['update:modelValue', 'input'])

const uiStore = useUiStore()

type Props = {
  modelValue: boolean
  newNumber?: Phone | null
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  newNumber: null,
})

const data = ref({
  country_id: props.newNumber ? props.newNumber.phone_country_id : null,
  phones: [
    {
      phone: {
        phone: props.newNumber ? props.newNumber.phone : null,
        phone_country_id: props.newNumber ? props.newNumber.phone_country_id : null,
      },
    },
  ],
})

const countries = ref<InputItemCountry[]>([])
const loading = ref(false)

onNuxtReady(async () => await getCountries())

const getCountries = async () => {
  const countriesList = await useCountries()
  countries.value = countriesList.map((c) => ({ text: c.name, value: c.id, ...c }))
}

const getPhoneCode = (countryId?: number) => {
  return countries.value.find((el) => el.id === countryId)?.phone_code
}

const validatePhone = (value: any) => {
  return useValidatePhone(value.phone, value.phone_country_id)
}

const rules = computed(() => ({
  country_id: { required: helpers.withMessage('The location is required', required) },
  phones: {
    $each: helpers.forEach({
      phone: {
        required: helpers.withMessage('Phone is required', required),
        validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
      },
    }),
  },
}))

const v$ = useVuelidate(rules, data, { $stopPropagation: true })

const addNumber = () => {
  data.value.phones.push({
    phone: {
      phone: null,
      phone_country_id: data.value.country_id ? data.value.country_id : null,
    },
  })
}

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  try {
    loading.value = true
    const payload = {
      country_id: data.value.country_id,
      can_sms: false,
      phones: data.value.phones.map(
        (el) => `+${getPhoneCode(el.phone.phone_country_id)}_${el.phone.phone?.toString()}`
      ),
    }

    const status = await useCreatePhone(payload)
    if (status === 'error') return
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}

const preselectCountryItems = () => {
  data.value.phones.forEach((num) => {
    if (num.phone.phone) return
    num.phone.phone_country_id = data.value.country_id
  })
}
</script>

<style scoped></style>
